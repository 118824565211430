@font-face {
  font-family: 'Dystopian';
  src: url('./assets//fonts/Dystopian-Regular.ttf');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'DystopianBold';
  src: url('./assets//fonts/Dystopian-Bold.ttf');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets//fonts/Poppins-Light.ttf');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'PoppinsMedium';
  src: url('./assets//fonts/Poppins-Medium.ttf');
  font-style: normal;
  font-weight: 600;
}

:root {
  --color-dark: #000000;
  --color-light: #FFFFFF;
  --color-warm: #E9E9E9;
  --color-text: #3A3A3A;
  --color-1: #00FF00;
  --color-2: #0000FF;
  --color-3: #FF0000;
  --color-4: #FD2EF5;
  --color-5: #F7EC13;
  --color-6: #A7A7A7;
  --border-menu: 2px solid #E9E9E9;
  --padding: 3rem 3.75rem;
}

@media screen and (max-width: 1118px) {
  :root {
    --padding: 1.875rem;
  }
}

html {
  height: -webkit-fill-available;
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: var(--color-dark);
  z-index: -1;
}